<template>
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">Usuário</div>
    <div class="text-500 mb-5">Cadastro de novos usuários no sistema.</div>
    <div class="surface-card p-4 shadow-2 border-round p-fluid mb-3">
      <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12">
          <label for="name" :class="{'p-error':v$.user.name.$error}" >Nome*</label>
          <div class="p-inputgroup">
            <InputText id="name"
                       @blur="v$.user.name.$touch"
                       type="text"
                       required="true"  v-model="user.name"
                       autofocus
                       :class="{'p-invalid':v$.user.name.$error}"
            />
          </div>
          <small class="p-error" v-for="error of v$.user.name.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="email" :class="{'p-error':v$.user.email.$error}">E-mail*</label>
          <div class="p-inputgroup">
            <InputText id="email"
                       type="email"
                       required="true"
                       autofocus
                       @blur="v$.user.email.$touch"
                       :class="{'p-invalid':v$.user.email.$error}"
                       v-model="user.email" />
          </div>
          <small class="p-error" v-for="error of v$.user.email.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="celular" :class="{'p-error':v$.user.celular.$error}">Celular*</label>
          <div class="p-inputgroup">
            <InputMask mask="(99) 99999-9999"
                       id="celular"
                       type="text"
                       required="true"
                       @blur="v$.user.celular.$touch"
                       :class="{'p-invalid':v$.user.celular.$error}"
                       v-model="user.celular"/>
          </div>
          <small class="p-error" v-for="error of v$.user.celular.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field mb-4 col-12 md:col-4">
          <label for="cpf" class="font-medium text-900">CPF</label>
          <InputMask mask="999.999.999-99" id="cpf" type="text" required v-model="user.cpf"/>
        </div>
        <div class="field mb-4 col-12 md:col-4">
          <label for="rg" class="font-medium text-900">RG / Conselho</label>
          <InputText id="rg" type="text" v-model="user.rg" required min="1" max="20" size="20" maxlength="20"/>
        </div>
        <div class="field mb-4 col-12 md:col-4">
          <label for="orgao" class="font-medium text-900">Orgão/Estado</label>
          <InputText id="orgao" type="text" v-model="user.orgaoEstado"/>
        </div>
        <div class="field mb-4 col-12 md:col-12">
          <label for="endereco" class="font-medium text-900">Endereço</label>
          <InputText id="endereco" type="text" v-model="user.endereco"/>
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="celular" :class="{'p-error':v$.user.roles.$error}">Permissão*</label>
          <div class="p-inputgroup">
            <MultiSelect v-model="user.roles"
                         :options="roles"
                         option-label="customName"
                         @blur="v$.user.roles.$touch"
                         :class="{'p-invalid':v$.user.roles.$error}"
                         placeholder="Selecione as Permissões"
                         required="true"
                         autofocus
                         display="chip" />
          </div>
          <small class="p-error" v-for="error of v$.user.roles.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
      </div>
    </div>
    <div class="col-12">
      <Toolbar class="mb-4">
        <template v-slot:end>
          <div class="my-0">
            <Button label="Cancelar" class="w-auto mt-3 p-button-outlined mr-2" @click.prevent="$router.back()"></Button>
            <Button label="Salvar" class="w-auto mt-3 p-button-info" @click="save"></Button>
          </div>
        </template>
      </Toolbar>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";
import UserService from "@/service/UserService";
import RoleService from "@/service/RoleService";

export default {
  name: "user",
  setup: () => ({ v$: useVuelidate() }),
  validations () {
    return {
      user: {
        name: {
          required: helpers.withMessage('Campo Nome é obrigatório.', required)
        },
        email: {
          required: helpers.withMessage('Campo Nome é obrigatório.', required),
          email: helpers.withMessage('E-mail informado é inválido.', email)
        },
        celular: {
          required: helpers.withMessage('Campo Celular é obrigatório.', required)
        },
        roles: {
          required: helpers.withMessage('Campo Permissão é obrigatório.', required)
        },
      }
    }
  },
  created() {
    this.userService = new UserService();
    this.roleService = new RoleService();
  },
  data() {
    return {
      userService: null,
      user: {},
      roles: [],
      roleService: null
    }
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.loadUser();
    await this.loadRoles();
    loader.hide();
  },
  methods: {

    async save() {
      const isFormValid = await this.v$.$validate();
      if (!isFormValid) {
        return;
      }
      const loader = this.$loading.show();
      try {
        const response = await this.userService.save(this.user);
        if (response.status === 200) {
          this.$toast.add({severity: 'success', summary: 'Sucesso', detail: 'Usuário salvo com sucesso.', life: 3000});
          this.clearForm();
        } else if (response.status === 210) {
          this.$toast.add({
            severity: 'warn',
            summary: 'Alerta',
            detail: 'E-mail informado já cadastrado no sistema.',
            life: 3000
          });
        }
        loader.hide();
      } catch(e) {
        loader.hide();
        this.$toast.add({
          severity: 'error',
          summary: 'ERRO',
          detail: 'Ocorreu um erro interno no servidor, tente novamente mais tarde.',
          life: 3000
        });
      }
    },

    clearForm() {
      this.user = {};
      this.v$.$reset();
    },

    // onEnviar(event){
    //   this.imageData = event.files[0];
    // },

    async loadUser() {
      const id = this.$route.query.id;
      if(id) {
        const response = await this.userService.findByClinicalAndId(id);
        if(response.status === 200) {
          this.user = response.data;
        }
      }
    },

    async loadRoles() {
      const response = await this.roleService.findAll();
      if(response.status === 200) {
        this.roles = response.data;
      }
    },
  }
}
</script>

<style scoped>
</style>
